import { Routes, Route } from "react-router-dom";
import Loadable from "../Components/Loadable";
import { lazy } from "react";
import PrivateRoute from "./PrivateRoute";

// =====================================|| Main Routing ||===================================== //

const Authentication = Loadable(
  lazy(() => import("../Pages/Authentication/Authentication")),
);

const Dashboard = Loadable(lazy(() => import("../Pages/Dashboard/Dashboard")));

const Settings = Loadable(lazy(() => import("../Pages/Settings/Settings")));

const ResultPage = Loadable(
  lazy(() => import("../Pages/ResultPage/ResultPage")),
);

const SchedulePage = Loadable(
  lazy(() => import("../Pages/SearchStudentSchedule/SearchStudentSchedule")),
);

const RoomAllocation = Loadable(
  lazy(() => import("../Pages/RoomAllocation/RoomAllocation")),
);

const PageNotFound = Loadable(
  lazy(() => import("../Pages/PageNotFound/PageNotFound")),
);

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Authentication />} />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />

      <Route
        path="/resultpage"
        element={
          <PrivateRoute>
            <ResultPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/examSession/:id"
        element={
          <PrivateRoute>
            <RoomAllocation />
          </PrivateRoute>
        }
      />

      <Route
        path="/examSession/:id/result"
        element={
          <PrivateRoute>
            <RoomAllocation />
          </PrivateRoute>
        }
      />

      <Route path="/searchSchedule" element={<SchedulePage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRoutes;
