import React from "react";
import { Navigate } from "react-router-dom";

// ---------------------------------------- Private Route ------------------------------------------ //

const PrivateRoute = ({ children }) => {
  const branchName = sessionStorage.getItem("branchName");

  return branchName ? children : <Navigate to="/" />;
};

export default PrivateRoute;
